import { Link } from "gatsby"
import React from "react"
import SEO from "../components/seo"
import "../styles/settings.scss"
import "../styles/style.scss"

const PrivacyPolicyPage = () => (
  <>
    <SEO title="Medical Disclaimer" />
    <div className="site-wrapper">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="about-wrapper">
              <div class="about-content">
                <h2>Medical Disclaimer</h2>
                <br />
                <p>
                  Virtus is not a healthcare company, but we care about your
                  health.
                </p>
                <p>
                  The App has been developed based on certain health parameters.
                  However, each individual is different, and you should con-sult
                  with your personal healthcare provider before beginning any
                  significant weight modification program. DO NOT BEGIN USING
                  THE RP APP IF YOU HAVE NOT RECEIVED MEDICAL CLEARANCE FROM
                  YOUR PERSONAL HEALTHCARE PROVIDER.
                </p>
                <p>Do Not Use The App IF:</p>
                <ul>
                  <li>
                    <p>- Your age is less than 18 years, or over 65 years</p>
                    <p>
                      - Your height is under 54 inches tall, or over 84 inches
                      tall
                    </p>
                    <p>- Your weight is over 350 pounds</p>
                    <p>- Your Body Mass Index (BMI) is less than 17</p>
                    <p>
                      - You are a transgender individual currently undergoing
                      hormone therapy
                    </p>
                    <p>- You are currently breastfeeding</p>
                    <p>- You have been diagnosed with heart disease</p>
                    <p>- You have been diagnosed with thyroid cancer</p>
                    <p>- You have polycystic ovary syndrome</p>
                    <p>- You have hyperthyroidism or hypothyroidism</p>
                    <p>
                      - You have undergone weight loss surgery in the past 5
                      years
                    </p>
                    <p>- You have gallbladder disease</p>
                    <p>- You are pregnant</p>
                    <p>- You have been diagnosed with an autoimmune disease</p>
                    <p>- You have a history of an eating disorder</p>
                    <p>
                      - You have been diagnosed with Type I or Type II diabetes
                    </p>
                  </li>
                </ul>
                <h3>Agreement and Release of Liability</h3>
                <br />
                <p>
                  In consideration of being allowed to partic-ipate in the
                  activities and programs offered by Virtus and to use its
                  programs and training, in addition to the payment of any fee
                  or charge, I do hereby waive, release and forever discharge
                  and hold harmless Virtus and its consultants, officers,
                  agents, and employees from any and all responsibility,
                  liability, cost and expenses, including injuries or damages,
                  resulting from my participation in any activities, or my use
                  of any programs designed by Virtus. I do also hereby release
                  Virtus, its consultants, officers, agents and employees from
                  any responsibility or liability for any injury, damage or
                  disorder (physical, metabolic, or otherwise) to myself, or in
                  any way arising out of or connected with my participation in
                  any activities with Virtus.
                </p>
                <p>
                  I understand and am aware that strength, flexibility, and
                  aerobic exercise, including the use of equipment are a
                  potentially hazardous activity. I also understand that fitness
                  activities involve a risk of injury and even death, and that I
                  am voluntarily participating in these activities and using
                  equipment and machinery with knowledge of the dangers
                  involved. I hereby agree to expressly assume and accept any
                  and all risks of injury or death related to said fitness
                  activities. In addition, I certify that I am 18 years of age
                  or older.
                </p>
                <p>
                  I agree that I am eligible to use the app according to the
                  exclusions detailed above.
                </p>
                <br />
                <Link to="/">Go Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default PrivacyPolicyPage
